import React from 'react';
import { BUDGET_TABS } from '../../utils/enum';
import {
  DayInput,
  LabelCreatable,
  LabelInput,
  SelectInput,
} from '../../../../components/Input/Input';
import { DefaultButton } from '../../../../components/Button/Button';
import { FaTrashCan } from 'react-icons/fa6';
import { inPlaceSort } from 'fast-sort';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ptBR } from '@mui/x-date-pickers/locales';
import { statusOptions } from '../../utils/constants';
import { TbEdit } from 'react-icons/tb';

export const DetailServices = (props) => {
  const {
    budget,
    onChange,
    client,
    values,
    setOpen,
    vehicle,
    menuOpen,
    creatableOpen,
    createOption,
    divCreatable,
    deleteOptions,
    paymentOptions,
    company,
    setCurrentClientId,
    setCurrentVehicleId,
  } = props;

  if (budget !== BUDGET_TABS.DETAILS) {
    return;
  }

  const OptionFunc = (props) => {
    const { data } = props;
    return (
      <div
        {...props}
        className="deletable-creatable"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '40px',
          padding: '10px',
        }}
      >
        <div
          onClick={(e) => divCreatable(data, e)}
          style={{
            textTransform: 'uppercase',
            width: '100%',
            height: '40px',
            alignContent: 'center',
          }}
        >
          {data?.label}
        </div>
        <div>
          <FaTrashCan
            style={{ color: 'rgb(255 130 120)' }}
            onClick={(e) => deleteOptions(data.id, e)}
          />
        </div>
      </div>
    );
  };

  const sortedPaymentOptions = paymentOptions.map((item) => ({
    value: item.payment_type.value,
    label: item.payment_type.label,
    id: item.id,
  }));

  inPlaceSort(sortedPaymentOptions).asc([
    (item) => item.label.replace(/[^a-zA-Z]/g, '').toLowerCase(),
    (item) => {
      const numberMatch = item.label.match(/\d+/);
      return numberMatch ? parseInt(numberMatch[0], 10) : 0;
    },
  ]);

  return (
    <div className="budget-details-inputs">
      <div className="budget-modal-main-div">
        <div className="size-dropdown">
          <div className="void-class">
            <SelectInput
              onChange={(event) => onChange('client', event)}
              placeholder={'Escolha o Cliente'}
              label={'Cliente'}
              // isSearchable={!isMobile}
              options={client}
              defaultValue={
                client?.find(
                  (item) =>
                    item.value === values?.client?.value ||
                    item.value === values?.client
                ) || null
              }
            />
          </div>
        </div>
        <DefaultButton
          className="action-button"
          label={'+'}
          onClick={() => {
            setOpen(3);
            setCurrentClientId(0);
          }}
        />

        <DefaultButton
          style={{ backgroundColor: '#6694ec' }}
          className="action-button"
          label={<TbEdit style={{ width: 20, height: 20 }} />}
          onClick={() => {
            setOpen(3);
            setCurrentClientId(
              typeof values.client === 'object'
                ? values.client.value
                : values.client
            );
          }}
        />
      </div>
      <div className="budget-modal-main-div">
        <div className="size-dropdown">
          <div className="void-class">
            <SelectInput
              // isSearchable={!isMobile}
              onChange={(event) => onChange('vehicle', event)}
              placeholder={'Escolha o Veículo'}
              label={'Veículo'}
              options={vehicle}
              // disabled={!values.client}
              defaultValue={
                vehicle?.find(
                  (item) =>
                    item.value === values?.vehicle?.value ||
                    item.value === values?.vehicle
                ) || null
              }
            />
          </div>
        </div>
        <DefaultButton
          className="action-button"
          label={'+'}
          onClick={() => {
            setOpen(4);
            setCurrentVehicleId(0);
          }}
        />

        <DefaultButton
          style={{ backgroundColor: '#6694ec' }}
          className="action-button"
          label={<TbEdit style={{ width: 20, height: 20 }} />}
          onClick={() => {
            setOpen(4);
            setCurrentVehicleId(
              typeof values.vehicle === 'object'
                ? values.vehicle.value
                : values.vehicle
            );
          }}
        />
      </div>
      <div className="payment-dropdown">
        <div onClick={(e) => creatableOpen(e)} className="void-class">
          <LabelCreatable
            menuIsOpen={menuOpen}
            onCreateOption={(name) => createOption(name, 'payment')}
            components={{ Option: (props) => OptionFunc(props) }}
            options={inPlaceSort(sortedPaymentOptions).asc([
              (item) => item.label.replace(/[^a-zA-Z]/g, '').toLowerCase(),
              (item) => {
                const numberMatch = item.label.match(/\d+/);
                return numberMatch ? parseInt(numberMatch[0], 10) : 0;
              },
            ])}
            defaultValue={values?.payment}
            onChange={(value) => onChange('payment', value)}
            placeholder={'INSIRA A FORMA DE PAGAMENTO'}
            label={'Forma de pagamento'}
            className="generic-input select-creatable budget-percentage-input budget-creatable"
            styles={{
              option: (base) => ({
                ...base,
                border: ``,
                height: '100%',
              }),
            }}
          />
        </div>
      </div>

      <div className="expiration-date">
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale="pt-br"
          location={ptBR}
          localeText={
            ptBR.components.MuiLocalizationProvider.defaultProps.localeText
          }
        >
          <DayInput
            value={values?.expiration}
            onChange={(value) => onChange('expiration', value)}
            placeholder={'Dias'}
            label={'Prazo de Validade'}
            className="validity-input budget-percentage-input"
          />
        </LocalizationProvider>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale="pt-br"
          locale={ptBR}
          localeText={
            ptBR.components.MuiLocalizationProvider.defaultProps.localeText
          }
        >
          <DayInput
            className="validity-input budget-percentage-input"
            value={values?.deadline}
            onChange={(value) => onChange('deadline', value)}
            placeholder={'Dias'}
            label={'Prazo de Entrega'}
          />
        </LocalizationProvider>
      </div>

      <div className="void-class">
        <SelectInput
          onChange={(value) => onChange('status', value)}
          placeholder={'Escolha o status do orçamento'}
          // isSearchable={!isMobile}
          label={'Status'}
          defaultValue={
            statusOptions.find((item) => item.id === values?.status) ||
            values?.status
          }
          value={statusOptions.find((item) => item.id === values?.status)}
          options={statusOptions}
          className="budget-percentage-input"
        />
      </div>

      <div style={{ paddingBottom: '10px' }}>
        <LabelInput
          type={'textarea'}
          inputClassname={'text-observation-min'}
          label={'Observações'}
          placeholder={'Insira a observação para o orçamento'}
          onChange={(value) => onChange('observation', value)}
          value={values?.observation ? values?.observation : company.budgetNote}
          className="budget-percentage-input"
        />
      </div>
      <div style={{ paddingBottom: '10px' }}>
        <LabelInput
          type={'textarea'}
          inputClassname={'text-observation-min2'}
          label={'Anotações'}
          placeholder={'Insira suas anotações'}
          onChange={(value) => onChange('annotation', value)}
          value={values?.annotation}
          className="budget-percentage-input"
        />
      </div>
    </div>
  );
};
