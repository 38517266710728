import { useEffect, useState, React } from 'react';
import {
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import { alertError, alertSuccess } from '../../components/Toast';
import { setData } from '../../core/redux/app/Actions';
import {
  deletedBudget,
  saveBudget,
  savePaymentTypes,
} from '../../core/utils/budget';
import { compareValues } from '../ModalClients/ModalClientsContainer';
import Swal from 'sweetalert2';
import { supabase } from '../../supabaseClient';
import ModalBudget from '../../screens/ModalBudget/ModalBudget';
import CryptoJS from 'crypto-js';
import {
  calculateTotalValue,
  changeValueAeServiceFn,
  changeValueBodyshop,
  partDetails,
  partValues,
  verifyTimesExist,
} from './utils/functions';
import { BUDGET_TABS } from '../../screens/ModalBudget/utils/enum';
import { sizeOptions } from '../../screens/ModalBudget/utils/constants';

const ModalBudgetContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const id = location.pathname.split('/')[3];
  const isNew = params.id === '0';
  const user = useSelector((state) => state.app.user || {});
  const company = useSelector((state) => state.app.company || {});
  const reloadBudget = useSelector((state) => state.app.reloadBudget || false);
  const reloadPaymentOptions = useSelector(
    (state) => state.app.reloadPaymentOptions || false
  );
  const [values, setValues] = useState({});
  const [oldValues, setOldValues] = useState({});
  const [responsible, setResponsible] = useState([]);
  const [budget, setBudget] = useState(1);
  const [addBudget, setAddBudget] = useState(null);
  const [open, setOpen] = useState(false);
  const [hasCreated, setHasCreated] = useState(false);
  const [menuOpen, setMenuOpen] = useState();
  const [client, setClient] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [bodyshopService, setBodyshopService] = useState([]);
  const [aeService, setAeService] = useState([]);
  const [serviceType, setServiceType] = useState([]);
  const [parts, setParts] = useState([]);
  const [part, setPart] = useState({});
  const [reload, setReload] = useState(false);
  const [hvTable, setHvTable] = useState([]);
  const [info, setInfo] = useState([]);
  const [aeInfo, setAeInfo] = useState([]);
  const [isntLinearHour, setIsntLinearHour] = useState(false);
  const [valuesBasedOnSize, setValuesBasedOnSize] = useState([]);
  const [hasInitialLength, setHasInitialLength] = useState(false);
  const [initialClientLength, setInitialClientLength] = useState(0);
  const [initialVehicleLength, setInitialVehicleLength] = useState(0);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [reloadPrint, setReloadPrint] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [printCompany, setPrintCompany] = useState({});

  const changeValue = (field, data, value, edited = false) => {
    setReloadPrint((reload) => !reload);
    setBodyshopService((array) =>
      changeValueBodyshop({ array, field, data, value, edited })
    );
  };

  const changePartsDetail = (field, data, value) => {
    setReloadPrint((reload) => !reload);
    setParts((array) => partDetails({ array, field, data, value, part }));
  };

  const changePartsValue = (field, data, value) => {
    setReloadPrint((reload) => !reload);
    setParts((array) => partValues({ array, field, data, value }));
  };

  const changeValueAeService = (field, data, value, edited = false) => {
    setReloadPrint((reload) => !reload);
    setAeService((array) =>
      changeValueAeServiceFn({ array, field, data, value, edited })
    );
  };

  useEffect(() => {
    loadPaymentTypes();
  }, [company.id, open, reloadPrint]);

  const loadPaymentTypes = async () => {
    let { data, error } = await supabase
      .from('PaymentTypes')
      .select('*')
      .eq('id_company', company.id)
      .is('deleted_at', null);

    if (!error) {
      setPaymentOptions(
        data.map((item) => ({ ...item, label: item.title, value: item.id }))
      );
    }
  };

  useEffect(() => {
    if (
      paymentOptions.length > 0 &&
      values?.payment &&
      !values?.payment?.value
    ) {
      setValues((old) => ({
        ...old,
        payment: paymentOptions?.find(
          (payment) => payment.label === old.payment
        )
          ? paymentOptions?.find((payment) => payment.value === old.payment)
          : { label: old.payment, value: old.payment },
      }));
      setOldValues((old) => ({
        ...old,
        payment: paymentOptions?.find(
          (payment) => payment.label === old.payment
        )
          ? paymentOptions?.find((payment) => payment.value === old.payment)
          : { label: old.payment, value: old.payment },
      }));
    }
  }, [values, paymentOptions]);

  useEffect(() => {
    if (open && !hasInitialLength && client && vehicle) {
      setInitialClientLength(client.length);
      setInitialVehicleLength(vehicle.length);
      setHasInitialLength((hasInitialLength) => hasInitialLength);
    }
  }, [open, client, vehicle]);

  useEffect(() => {
    if (
      !open &&
      vehicle.length > initialVehicleLength &&
      initialVehicleLength !== 0
    ) {
      const sortedVehicle = vehicle.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      );
      const mostRecentVehicle = sortedVehicle[0];

      setReload((reload) => reload);
      setValues((prevValues) => ({
        ...prevValues,
        vehicle: mostRecentVehicle,
      }));
    }
  }, [open, vehicle, initialVehicleLength]);

  useEffect(() => {
    if (
      !open &&
      (client.length > initialClientLength) & (initialClientLength !== 0)
    ) {
      const mostRecentClient = client.reduce((prev, current) => {
        const prevDate = new Date(prev.date).getTime();
        const currentDate = new Date(current.date).getTime();
        return currentDate > prevDate ? current : prev;
      });

      setReload((reload) => reload);
      setValues((prevValues) => ({
        ...prevValues,
        client: mostRecentClient,
      }));
    }
  }, [open, client, initialClientLength]);

  const decidePriceBasedOnSize = async (values, info) => {
    if (values?.size) {
      let chosenSize = values?.size;

      const filteredData = info.map((item) => {
        if (chosenSize.id == 2 || chosenSize.id == '2') {
          return {
            value: item.hasOwnProperty('p_value') ? item.p_value : null,
            time: item.hasOwnProperty('p_time') ? item.p_time : null,
            title: item.title,
            id: item.id,
          };
        } else if (chosenSize.id == 3 || chosenSize.id == '3') {
          return {
            value: item.hasOwnProperty('m_value') ? item.m_value : null,
            time: item.hasOwnProperty('m_time') ? item.m_time : null,
            title: item.title,
            id: item.id,
          };
        } else if (chosenSize.id == 4 || chosenSize.id == '4') {
          return {
            value: item.hasOwnProperty('g_value') ? item.g_value : null,
            time: item.hasOwnProperty('g_time') ? item.g_time : null,
            title: item.title,
            id: item.id,
          };
        } else {
          return {};
        }
      });

      // const newValuesBasedOnSize = filteredData.filter((item) =>
      //   Object.values(item).every((value) => value !== null)
      // );
      setValuesBasedOnSize(filteredData);
    }
  };

  useEffect(() => {
    decidePriceBasedOnSize(values, info);
  }, [values, info]);

  // Nota:
  // Esse useEffect abaixo é responsável pelas operações envolvendo as trocas entre hora linear,
  // hora tabela, adicional sobre pintura e também valores baseados no tamanho.
  useEffect(() => {
    if (values?.percentage === undefined) {
      values.percentage = 0;
    }

    setReloadPrint((reload) => !reload);
    setBodyshopService((prevService) => {
      if (prevService) {
        return prevService.map((item) => {
          let updatedItem = { ...item };
          let parsedTypes;
          let tableValue;
          let matchingParsedType;
          let matchingParsedTypeValue;
          let matchingTableService;

          if (updatedItem.time === undefined) {
            updatedItem.time = '00:00';
          }

          const formatValue = (value) =>
            value && value.length >= 2 && value.substr(0, 2) === 'R$'
              ? value.substr(2)
              : value;

          const paintService =
            updatedItem?.title &&
            updatedItem?.title?.toLowerCase().includes('pintura');

          // Se FOR hora linear *ou seja, se o valor NÃO vier de alguma tabela valor-hora*
          if (
            !isntLinearHour &&
            values?.price !== undefined
            // &&
            // (values.price !== prevValues.price ||
            //   values.percentage !== prevValues.percentage)
          ) {
            const sanitizedUnitaryValue =
              values?.price && typeof values?.price === 'string'
                ? values?.price.length >= 2 &&
                  values?.price.substr(0, 2) === 'R$'
                  ? values?.price.substr(2).replace(',', '.')
                  : values?.price.replace(',', '.')
                : '';

            if (prevService) {
              if (
                paintService &&
                values?.percentage !== undefined &&
                values?.percentage !== 0
              ) {
                updatedItem.unitaryValue =
                  parseFloat(sanitizedUnitaryValue) +
                  (parseFloat(sanitizedUnitaryValue) * values?.percentage) /
                    100;
                updatedItem.time = updatedItem.time;
              } else {
                updatedItem.unitaryValue = item.editedPrice
                  ? item.unitaryValue
                  : sanitizedUnitaryValue;
                updatedItem.time = item.editedHour
                  ? item.time
                  : updatedItem.time;
              }
            } else {
              updatedItem.unitaryValue = item.editedPrice
                ? item.unitaryValue
                : updatedItem.unitaryValue;
              updatedItem.time = item.editedHour ? item.time : updatedItem.time;
            }
          } else {
            if (
              (values?.price === undefined ||
                values?.price === null ||
                values?.price === 0) &&
              (prevService.unitaryValue === undefined ||
                prevService.unitaryValue === null ||
                prevService.unitaryValue === 0)
            ) {
              updatedItem.unitaryValue = item.editedPrice
                ? item.unitaryValue
                : prevService.unitaryValue;
            } else {
              updatedItem.unitaryValue = item.editedPrice
                ? item.unitaryValue
                : updatedItem.unitaryValue;
            }
            updatedItem.time = item.editedHour ? item.time : updatedItem.time;
          }

          // Se não for hora linear *ou seja, se o valor vier de alguma tabela valor-hora*
          if (
            isntLinearHour &&
            values?.price !== undefined
            // values?.percentage !== prevvalues?.percentage
          ) {
            if (prevService) {
              tableValue = values?.price;

              if (
                typeof tableValue === 'string' &&
                tableValue.length <= 4 &&
                /^\d+$/.test(tableValue)
              ) {
                tableValue = hvTable.find(
                  (item) => item.id === Number(tableValue)
                );
              }

              parsedTypes =
                tableValue && tableValue.service_types
                  ? JSON.parse(tableValue.service_types)
                  : [];

              matchingTableService =
                updatedItem?.title &&
                updatedItem?.title?.toLowerCase().includes('pintura')
                  ? updatedItem
                  : false;

              matchingParsedType = parsedTypes.find(
                (parsedItem) => parsedItem.id === item.service_type
              );

              matchingParsedTypeValue = formatValue(matchingParsedType?.value);

              if (matchingTableService && values?.percentage !== undefined) {
                if (paintService) {
                  updatedItem.unitaryValue =
                    parseFloat(matchingParsedTypeValue) +
                    (parseFloat(matchingParsedTypeValue) * values?.percentage) /
                      100;
                } else {
                  updatedItem.unitaryValue = item.editedPrice
                    ? item.unitaryValue
                    : parseFloat(matchingParsedTypeValue);
                }
              } else {
                const loadedUnitaryValue = (
                  matchingParsedTypeValue ?? ''
                ).replace(',', '.');
                updatedItem.unitaryValue = item.editedPrice
                  ? item.unitaryValue
                  : parseFloat(loadedUnitaryValue);
              }
            } else {
              updatedItem.unitaryValue = item.editedPrice
                ? item.unitaryValue
                : updatedItem.unitaryValue;
            }
            updatedItem.unitaryValue = item.editedPrice
              ? item.unitaryValue
              : updatedItem.unitaryValue;
            updatedItem.time = item.editedHour ? item.time : updatedItem.time;
          }

          // Caso haja algum item com a classificação de serviço como PINTURA, pra aplicar
          // o adicional sobre pintura. Além disso, também adiciona o valor e tempo baseado no tamanho escolhido.
          if (
            values?.price === undefined ||
            values?.price == 0 ||
            values?.price == 'R$'
          ) {
            if (prevService) {
              if (paintService) {
                if (valuesBasedOnSize.length > 0) {
                  for (let i = 0; i < valuesBasedOnSize.length; i++) {
                    if (
                      valuesBasedOnSize[i].value >= 0 &&
                      valuesBasedOnSize[i].time !== 0 &&
                      valuesBasedOnSize[i].title === updatedItem.title
                    ) {
                      updatedItem.unitaryValue =
                        parseFloat(valuesBasedOnSize[i].value) +
                        (parseFloat(valuesBasedOnSize[i].value) *
                          values?.percentage) /
                          100;
                      updatedItem.time = valuesBasedOnSize[i].time;
                    } else {
                      updatedItem.unitaryValue = item.editedPrice
                        ? item.unitaryValue
                        : updatedItem.unitaryValue;
                      updatedItem.time = item.editedHour
                        ? item.time
                        : updatedItem.time;
                    }
                  }
                } else {
                  updatedItem.unitaryValue = item.editedPrice
                    ? item.unitaryValue
                    : updatedItem.unitaryValue;
                  updatedItem.time = item.editedHour
                    ? item.time
                    : updatedItem.time;
                }
              } else {
                for (let i = 0; i < valuesBasedOnSize.length; i++) {
                  if (
                    valuesBasedOnSize[i].value >= 0 &&
                    valuesBasedOnSize[i].time !== 0 &&
                    valuesBasedOnSize[i].title === updatedItem.title
                  ) {
                    updatedItem.unitaryValue = item.editedPrice
                      ? item.unitaryValue
                      : valuesBasedOnSize[i].value;
                    updatedItem.time = item.editedHour
                      ? item.time
                      : valuesBasedOnSize[i].time;
                  } else {
                    updatedItem.unitaryValue = item.editedPrice
                      ? item.unitaryValue
                      : updatedItem.unitaryValue;
                    updatedItem.time = item.editedHour
                      ? item.time
                      : updatedItem.time;
                  }
                }
              }
            }
          } else {
            for (let i = 0; i < valuesBasedOnSize.length; i++) {
              if (
                valuesBasedOnSize[i].value >= 0 &&
                valuesBasedOnSize[i].time !== 0 &&
                valuesBasedOnSize[i].title === updatedItem.title
              ) {
                // updatedItem.unitaryValue = valuesBasedOnSize[i].value
                updatedItem.time = item.editedHour
                  ? item.time
                  : valuesBasedOnSize[i].time;
              } else {
                updatedItem.unitaryValue = item.editedPrice
                  ? item.unitaryValue
                  : updatedItem.unitaryValue;
                updatedItem.time = item.editedHour
                  ? item.time
                  : updatedItem.time;
              }
            }
          }

          updatedItem.value = calculateTotalValue(
            updatedItem.time,
            typeof updatedItem?.unitaryValue === 'string'
              ? updatedItem?.unitaryValue?.replace(',', '.')
              : updatedItem.unitaryValue
          );

          return updatedItem;
        });
      }
    }, []);
  }, [values, valuesBasedOnSize, bodyshopService.length]);

  useEffect(() => {
    loadHourlyValue();
  }, [company]);
  useEffect(() => {
    if (id !== '0') {
      loadData();
    }
  }, [id]);

  useEffect(() => {
    const path = location.pathname.split('/')[1];
    dispatch(
      setData({ [`compareValues_${path}`]: compareValues(oldValues, values) })
    );
  }, [values, oldValues]);

  useEffect(() => {
    loadClient();
  }, [company.id, reload]);

  useEffect(() => {
    loadResponsible();
  }, [company.id]);

  useEffect(() => {
    loadServices();
    loadAestheticServices();
  }, [company]);

  const loadServices = async () => {
    let { data, error } = await supabase
      .from('Services')
      .select('*')
      .eq('id_company', company.id)
      .is('deleted_at', null);
    if (!error) {
      getServiceTable(data);
    }
  };

  const loadAestheticServices = async () => {
    let { data, error } = await supabase
      .from('AestheticServices')
      .select('*')
      .eq('id_company', company.id)
      .is('deleted_at', null);
    if (!error) {
      getAestheticServiceTable(data);
    }
  };

  const getServiceTable = async (data) => {
    const dataFormatter =
      values?.price && values?.price.service_types
        ? JSON.parse(values?.price.service_types)
        : [];

    const newData = data.map((item) =>
      item.service_type === budget
        ? {
            ...item,
            unitaryValue: dataFormatter?.find((item) => item.id === budget)
              ?.value,
          }
        : item
    );

    setInfo(newData);
  };

  const getAestheticServiceTable = async (data) => {
    const dataFormatter =
      values?.price && values?.price.service_types
        ? JSON.parse(values?.price.service_types)
        : [];

    const newData = data.map((item) =>
      item.service_type === budget
        ? {
            ...item,
            unitaryValue: dataFormatter?.find((item) => item.id === budget)
              ?.value,
          }
        : item
    );

    setAeInfo(newData);
  };

  useEffect(() => {
    getServiceTable(info);
    getAestheticServiceTable(aeInfo);
  }, []);

  const loadHourlyValue = async () => {
    let { data, error } = await supabase
      .from('HourlyValue')
      .select('*')
      .eq('id_company', company.id)
      .is('deleted_at', null);
    if (!error) {
      setHvTable(
        data.map((item) => ({ ...item, label: item.title, value: item.id }))
      );
    }
  };

  const showBudgetItems = (id, budget = false, items) => {
    if (budget) {
      const budgetFilter = addBudget || [];
      setAddBudget(
        budgetFilter.filter((item) => item.id === id).length > 0
          ? budgetFilter.filter((item) => item.id !== id)
          : budgetFilter.concat(items)
      );
    } else {
      setBudget(id);
    }
  };

  const loadData = async () => {
    let { data, error } = await supabase
      .from('Budget')
      .select(
        `
        *,
        client_name:Client(name),
        Vehicle(responsible, *)
        `
      )
      .eq('id', id)
      .is('deleted_at', null);
    if (!error) {
      const valuesSize = () => {
        const size = data?.[0]?.size;
        if (!size) return sizeOptions[1];
        if (size === 2) {
          return sizeOptions[0];
        }
        if (size === 3) {
          return sizeOptions[1];
        }
        if (size === 4) {
          return sizeOptions[2];
        }
      };
      setValues({
        ...data[0],
        client_name: data?.[0]?.client_name?.name,
        size: valuesSize(),
      });
      setIsntLinearHour(data[0]?.hour);
      setOldValues(data[0]);
      setBodyshopService(JSON.parse(data[0]?.service) || []);
      setAeService(
        JSON.parse(data[0]?.ae_service)?.map((item) => ({
          ...item,
          unitaryValue: item.unitaryValue || item.p_value,
          quantity: item.quantity || 1,
        })) || []
      );
      setParts(JSON.parse(data[0]?.parts) || []);
    }
  };

  useEffect(() => {
    if (id !== '0') {
      loadData();
    } else if (id === '0') {
      loadCompany();
      setValues((values) => ({
        ...values,
        observation: company.budgetNote,
        percentage: values?.percentage ? values?.percentage : 0,
        size: values?.size?.value
          ? values?.size
          : { id: 3, value: 'M', label: 'M' },
        status: values?.status?.values
          ? values?.status
          : {
              id: 4,
              value: 'Pendente',
              label: 'Pendente',
              color: '#b36a22',
            },
      }));
      setOldValues((values) => ({
        ...values,
        observation: company.budgetNote,
        percentage: values?.percentage ? values?.percentage : 0,
        size: values?.size?.value
          ? values?.size
          : { id: 3, value: 'M', label: 'M' },
        status: values?.status?.values
          ? values?.status
          : {
              id: 4,
              value: 'Pendente',
              label: 'Pendente',
              color: '#b36a22',
            },
      }));
    }
  }, [id, company]);

  useEffect(() => {
    loadVehicleNoClient();
  }, [values?.client?.value || values?.client, reload]);

  const addedParts =
    values?.parts !== undefined ? JSON.parse(values?.parts) : undefined;

  const addedServices = values?.service
    ? JSON.parse(values?.service)
    : undefined;

  const closeModal = () => {
    if (compareValues(oldValues, values)) {
      Swal.fire({
        title: 'Deseja sair sem salvar?',
        showCancelButton: true,
        showConfirmButton: false,
        showDenyButton: true,
        icon: 'warning',
        text: 'Ao sair, seus dados seram descartados.',
        denyButtonText: `Sair`,
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isDenied) {
          history.push('/budgets');
        }
      });
    } else {
      history.push('/budgets');
    }
  };

  const loadResponsible = async () => {
    let { data, error } = await supabase
      .from('Client')
      .select('*')
      .eq('id_company', company.id)
      .is('deleted_at', null);

    if (!error) {
      setResponsible(
        data.map((item) => ({
          label: item.name,
          value: item.id,
          cellphone: item.cellphone,
        }))
      );
    }
  };

  const loadClient = async () => {
    let { data, error } = await supabase
      .from('Client')
      .select(
        `
        *,
        Vehicle(responsible, *),
        Origin(id, *)
        `
      )
      .eq('id_company', company.id)
      .is('deleted_at', null);

    if (!error) {
      setClient(
        data.map((item) => ({
          label: item.name,
          value: item.id,
          date: item.created_at,
          cnpj: item.cnpj,
          cpf: item.cpf,
          telephone: item.telephone,
          cellphone: item.cellphone,
        }))
      );
    }
  };

  const loadVehicleNoClient = async () => {
    let { data, error } = await supabase
      .from('Vehicle')
      .select('*')
      .is('deleted_at', null)
      .eq('company', company.id);

    if (!error) {
      setVehicle(
        data.map((item) => ({
          label: `${item.plate} / (${item.model})`,
          value: item.id,
          date: item.created_at,
        }))
      );
    }
  };

  useEffect(() => {
    loadCompany();
  }, []);

  const loadCompany = async () => {
    let { data, error } = await supabase
      .from('Company')
      .select('*')
      .eq('id', company.id)
      .is('deleted_at', null);

    if (!error) {
      setPrintCompany(data[0]);
    } else {
      console.log(error);
    }
  };

  const generateBudgetCode = async (companyId) => {
    const today = new Date();
    const year = today.getFullYear().toString().slice(-2);
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const { data: existingBudgets, error } = await supabase
      .from('Budget')
      .select('id')
      .is('deleted_at', null)
      .eq('id_company', companyId)
      .gte('created_at', `${dateWithoutTimezone(today).slice(0, 10)}T00:00:00`)
      .lte('created_at', `${dateWithoutTimezone(today).slice(0, 10)}T23:59:59`);
    const numberOfBudgets =
      existingBudgets && existingBudgets.length > 0
        ? existingBudgets.length
        : 0;
    const codeNumber = String(numberOfBudgets).padStart(2, '0');

    return `O${year}${month}${day}${codeNumber}`;
  };

  const onSave = async (functionPrint = undefined) => {
    const newBudgetCode = await generateBudgetCode(company.id);

    try {
      delete values.Vehicle;

      if (values && !values.client) {
        alertError('Cliente é obrigatório');
        return;
      }

      if (values && !values.vehicle) {
        alertError('Veículo é obrigatório');
        return;
      }

      if (values && !values.size) {
        alertError('Tamanho é obrigatório');
        return;
      }

      if (values && !values.status) {
        alertError('Status é obrigatório');
        return;
      }

      if (!verifyTimesExist(bodyshopService)) {
        setBudget(BUDGET_TABS.BODYSHOP);
        alertError('Campo de horas com valor zerado');
        return;
      }

      const saveResult = await saveBudget(values?.id, {
        ...values,
        created_at: dateWithoutTimezone(new Date()),
        code: values && values.code ? values.code : newBudgetCode,
        payment:
          (values && values.payment && values.payment.id) || values.payment,
        size: (values && values.size && values.size.id) || values.size,
        status: (values && values.status && values.status.id) || values.status,
        hour: (values && values?.hour) || values?.hour,
        price: values?.hour
          ? typeof values?.price === 'object'
            ? values?.price.id || 0
            : typeof values?.price === 'string'
            ? parseFloat(
                values?.price.replace('R$', '').replace(',', '.').trim()
              ) || 0
            : values?.price || 0
          : typeof values?.price === 'string'
          ? parseFloat(
              values?.price.replace('R$', '').replace(',', '.').trim()
            ) || 0
          : values?.price || 0,
        client:
          (values && values.client && values.client.value) || values.client,
        client_name:
          (values && values.client && values.client.label) ||
          values.client_name,
        id_company: company.id,
        vehicle:
          (values && values.vehicle && values.vehicle.value) || values.vehicle,
        vehicle_name:
          (values && values.vehicle && values.vehicle.label) ||
          values.vehicle_name,
        service: JSON.stringify(
          bodyshopService.map((item, index) => ({ ...item, id: index })) || []
        ),
        parts: JSON.stringify(
          parts.map((item, index) => ({ ...item, id: index })) || []
        ),
        ae_service: JSON.stringify(
          aeService.map((item, index) => ({ ...item, id: index })) || []
        ),
      });
      const { error, data } = saveResult;
      if (!error) {
        alertSuccess('Sucesso ao salvar o orçamento!');
        history.push(`/budgets/details/${data[0].id}`);
        dispatch(setData({ reloadBudget: !reloadBudget }));

        // setValues(data[0]);
        // setOldValues(data[0]);
        if (functionPrint) {
          functionPrint(); // check this l8r
        }
      } else {
        alertError('Erro ao salvar o orçamento, tente novamente mais tarde.');
      }
    } catch (error) {
      alertError('Erro ao salvar o orçamento, tente novamente mais tarde.');
    }

    if (hasCreated !== false) {
      try {
        const savePayment = await savePaymentTypes({
          id_company: company.id,
          payment_type: values.payment,
        });

        const { error, data } = savePayment;
        if (!error) {
          alertSuccess('Sucesso ao salvar a opção de pagamento!');
          dispatch(setData({ reloadPaymentOptions: !reloadPaymentOptions }));
        } else {
          alertError(
            'Erro ao salvar a opção de pagamento, tente novamente mais tarde.'
          );
        }
      } catch (error) {
        alertError(
          'Erro ao salvar a opção de pagamento, tente novamente mais tarde.'
        );
      }
    }
  };

  const onChange = (field, value) => {
    setReloadPrint((reload) => !reload);
    setValues((values) => ({ ...values, [field]: value }));
  };

  const onRemove = async () => {
    const confirmation = await Swal.fire({
      title: 'Deseja mesmo excluir?',
      showCancelButton: true,
      showConfirmButton: false,
      showDenyButton: true,
      icon: 'warning',
      text: 'Seus dados serão completamente perdidos!',
      denyButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
    });

    if (confirmation.isDenied) {
      if (values && values.id) {
        const { data, error } = await deletedBudget(values.id, user.id);

        if (!error) {
          alertSuccess('Orçamento removido com sucesso!');
          history.push('/budgets');
          dispatch(setData({ reloadBudget: !reloadBudget }));
        } else {
          alertError('Erro ao remover o cliente, tente novamente mais tarde');
        }
      } else {
        alertError('Erro ao remover o cliente, tente novamente mais tarde');
      }
    } else {
      history.push('/budgets');
    }
  };

  const createOption = async (name, field) => {
    setReloadPrint((reload) => !reload);
    setValues((values) => ({
      ...values,
      [field]: { label: name, value: '0' },
    }));

    setHasCreated((hasCreated) => !hasCreated);
  };

  const onRemovePiece = (data) => {
    setReloadPrint((reload) => !reload);
    setParts((oldParts) => oldParts.filter((part) => part.id !== data.id));
  };
  const onRemoveService = (data) => {
    setReloadPrint((reload) => !reload);
    setBodyshopService((oldServices) =>
      oldServices.filter((service) => service.id !== data.id)
    );
  };
  const onRemoveAeService = (data) => {
    setReloadPrint((reload) => !reload);
    setAeService((oldAeServices) =>
      oldAeServices.filter((aeService) => aeService.id !== data.id)
    );
  };

  const sendWhatsapp = async () => {
    const clientId = values?.client;
    const clientName = values?.client_name;
    const clientVehicle = values?.Vehicle?.model;
    const matchingClient = client.find((item) => item.value === clientId);

    if (matchingClient && matchingClient.cellphone) {
      const phoneUser = encodeURIComponent(matchingClient.cellphone);
      const budgetId = values.id;

      setValues(() => ({
        ...values,
        status: values?.status?.values
          ? values?.status
          : {
              id: 2,
              value: 'Enviado',
              label: 'Enviado',
              color: '#b5b5b5',
            },
      }));

      if (values?.shared_at === null) {
        await saveBudget(budgetId, {
          shared_at: new Date(),
        });
      }
      var encrypted = CryptoJS.AES.encrypt(
        `${budgetId}`,
        // eslint-disable-next-line no-undef
        process.env.REACT_APP_PASSWORD_CRYPTO
      ).toString();
      var encoded = CryptoJS.enc.Base64.parse(encrypted).toString(
        CryptoJS.enc.Hex
      );

      const fullUrl = `https://prospera.app.br/external-budget/${encoded}`;

      window.open(
        `https://api.whatsapp.com/send?phone=55${phoneUser}&text=${encodeURIComponent(
          `Olá ${clientName}, somos da empresa ${company?.tradingName}.\nSegue o link do orçamento do seu veículo ${clientVehicle}, para aprovar, basta entrar abaixo e aceitar!\n${fullUrl}`
        )}`,
        '_blank'
      );
    }
  };

  const onChangePiece = (field, value) => {
    setPart((part) => ({ ...part, [field]: value }));
  };

  const openEditModal = (item) => {
    setIsOpen(5);
    setPart({
      ...item,
      profit: item?.profit ? item.profit : undefined,
      discount: item?.discount ? item.discount : undefined,
      gross_price: item?.value,
      images: item?.images?.images
        ? JSON.parse(item?.images?.images)
        : item?.images
        ? ''
        : '',
    });
  };

  const deleteOptions = async (id, e) => {
    e.preventDefault();
    e.stopPropagation();
    // setPaymentOptions(paymentOptions.filter(options => options.id !== id))
    const { data, error } = await supabase
      .from('PaymentTypes')
      .update({ deleted_at: new Date() })
      .eq('id', id);

    if (!error) {
      setPaymentOptions(
        paymentOptions.filter((options) => options.id !== parseInt(id))
      );
      alertSuccess('Deletado com sucesso');
    }
  };

  const divCreatable = (data, e) => {
    if (data.id) {
      e.preventDefault();
      onChange('payment', { label: data.label, value: data.id });
    } else {
      setValues((values) => ({
        ...values,
        payment: { label: data.value, value: '0' },
      }));
      setHasCreated((hasCreated) => !hasCreated);
    }
    setReloadPrint((reload) => !reload);
    e.type === 'click' ? setMenuOpen(false) : null;
  };

  const creatableOpen = (e) => {
    if (e.target.id === 'react-select-6-input') {
      setMenuOpen(true);
    } else if (e.target.id === 'path') {
      setMenuOpen(true);
    }
    // if (e.target.id === '') {
    //   setMenuOpen(true)
    // }
  };
  // const createOrigin = async (name) => {
  //   const response = await saveOrigin({ name, company: company.id });
  //   if (response && !response.error) {
  //     setOrigins((origins) => response.data.concat(origins));
  //     const { id, name } = response.data[0];
  //     onChange(`origin`, { value: id, label: name });
  //   }
  // };
  // console.log({oldValues})
  // console.log({parts}, compareValues, values )

  return (
    <ModalBudget
      compareValues={
        isNew
          ? compareValues({ oldValues }, { values })
          : compareValues(oldValues, {
              ...values,
              parts: JSON.stringify(parts || []),
              service: JSON.stringify(bodyshopService || []),
              ae_service: JSON.stringify(aeService || []),
            })
      }
      isNewValue={compareValues(values, oldValues, {
        ...values,
        parts: JSON.stringify(parts || []),
        service: JSON.stringify(bodyshopService || []),
        ae_service: JSON.stringify(aeService || []),
      })}
      creatableOpen={creatableOpen}
      menuOpen={menuOpen}
      divCreatable={divCreatable}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      openEditModal={openEditModal}
      onChangePiece={onChangePiece}
      part={part}
      setPart={setPart}
      company={company}
      printCompany={printCompany}
      reloadPrint={reloadPrint}
      addedParts={addedParts}
      calculateTotalValue={calculateTotalValue}
      valuesBasedOnSize={valuesBasedOnSize}
      changeValue={changeValue}
      changePartsValue={changePartsValue}
      changePartsDetail={changePartsDetail}
      addedServices={addedServices}
      serviceType={serviceType}
      setServiceType={setServiceType}
      setAddBudget={setAddBudget}
      responsible={responsible}
      isntLinearHour={isntLinearHour}
      setIsntLinearHour={setIsntLinearHour}
      closeModal={closeModal}
      onChange={onChange}
      values={values}
      isNew={isNew}
      onSave={onSave}
      paymentOptions={paymentOptions}
      setPaymentOptions={setPaymentOptions}
      sendWhatsapp={sendWhatsapp}
      client={client}
      setClient={setClient}
      vehicle={vehicle}
      setVehicle={setVehicle}
      budget={budget}
      open={open}
      addBudget={addBudget}
      setOpen={setOpen}
      bodyshopService={bodyshopService}
      setBodyshopService={setBodyshopService}
      aeService={aeService}
      setAeService={setAeService}
      reload={reload}
      setReload={setReload}
      parts={parts}
      setParts={setParts}
      showBudgetItems={showBudgetItems}
      onRemove={onRemove}
      onRemovePiece={onRemovePiece}
      onRemoveService={onRemoveService}
      onRemoveAeService={onRemoveAeService}
      hvTable={hvTable}
      createOption={createOption}
      deleteOptions={deleteOptions}
      changeValueAeService={changeValueAeService}
      // origins={origins}
      // createOrigin={createOrigin}
    />
  );
};

export default ModalBudgetContainer;

const dateWithoutTimezone = (date) => {
  const tzoffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
  const withoutTimezone = new Date(date.valueOf() - tzoffset)
    .toISOString()
    .slice(0, -1);
  return withoutTimezone;
};
