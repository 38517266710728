import { FaCheck, FaQuestion } from 'react-icons/fa';
import {
  ColumnsPieceServiceRenderer,
  PieceActionCell,
} from '../components/ColumnsPieceServiceRenderer';
import {
  ActionCell,
  ColumnsServiceRenderer,
} from '../components/ColumnsServiceRenderer';
import { FaMagnifyingGlass } from 'react-icons/fa6';

export const statusOptions = [
  { id: 4, value: 'Pendente', label: 'Pendente', color: '#b36a22' },
  { id: 1, value: 'Encerrado', label: 'Encerrado', color: '#c95555' },
  { id: 2, value: 'Enviado', label: 'Enviado', color: '#b5b5b5' }, //check
  { id: 3, value: 'Aprovado', label: 'Aprovado', color: '#00A86B' }, //check
  {
    id: 5,
    value: 'Aprovado Parcial',
    label: 'Aprovado Parcial',
    color: '#6eb6ff',
  },
  { id: 6, value: 'Repescado', label: 'Repescado', color: '#eead2d' },
  { id: 7, value: 'Reprovado', label: 'Reprovado', color: '#c95555' },
  {
    id: 8,
    value: 'Serviço Realizado',
    label: 'Serviço Realizado',
    color: '#32CD32',
  },
  { id: 9, value: 'Agendado', label: 'Agendado', color: '#4682B4' },
];

export const OSStatusOptions = [
  { id: 1, value: 'open', label: 'Aberta' },
  { id: 2, value: 'closed', label: 'Fechada' },
  { id: 3, value: 'finished', label: 'Quitada' },
]

export const columnsService = (onRemove, values, changeValue, budget) => [
  {
    id: 1,
    field: 'title',
    label: 'Serviço',
    width: '1fr',
    className: 'field-description',
    resizable: false,
    cellRenderer: ({ data }) => (
      <ColumnsServiceRenderer
        changeValue={changeValue}
        data={data}
        budget={budget}
      />
    ),
  },
  {
    id: 5,
    field: 'actions',
    label: 'Ações',
    resizable: false,
    width: '90px',
    cellRenderer: ({ data }) => <ActionCell data={data} onRemove={onRemove} />,
  },
];

export const sizeOptions = [
  { id: 2, value: 'P', label: 'P' },
  { id: 3, value: 'M', label: 'M' },
  { id: 4, value: 'G', label: 'G' },
];

export const columnsPiece = (
  onRemove,
  changePartsValue,
  isOpen,
  setOpen,
  openEditModal
) => [
  {
    id: 1,
    field: 'name',
    label: 'Peça',
    width: '1fr',
    className: 'field-description',
    resizable: false,
    cellRenderer: ({ data }) => (
      <ColumnsPieceServiceRenderer
        data={data}
        changePartsValue={changePartsValue}
      />
    ),
  },
  {
    id: 5,
    field: 'actions',
    label: 'Ações',
    resizable: false,
    width: '90px',
    cellRenderer: ({ data }) => (
      <PieceActionCell
        data={data}
        onRemove={onRemove}
        isOpen={isOpen}
        setOpen={setOpen}
        openEditModal={openEditModal}
      />
    ),
  },
];

export const statusBadges = [
  { id: 3, value: <FaCheck />, label: 'Aprovado' },
  { id: 5, value: <FaMagnifyingGlass />, label: 'Analisando' },
  { id: 6, value: <FaQuestion />, label: 'Em dúvida' },
];
