import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { DefaultButton } from "../Button/Button";
import { isMobile } from "react-device-detect";
import { AiOutlineCheck, AiOutlineClose, AiOutlineWhatsApp, AiOutlineExpand } from "react-icons/ai";
import {
  DropzoneInput,
  LabelInput,
  MoneyInput,
  PercentageInput,
} from "../Input/Input";
import { alertError, alertSuccess } from "../Toast";
import { supabase } from "../../supabaseClient";

const PieceEdit = ({
  isOpen,
  setOpen,
  onChangePiece,
  part,
  setPart,
  setParts,
}) => {
  useEffect(() => {
    if (part.quantity && part.unitaryValue) {
      setPart((parts) => ({ ...parts, gross_price: brutePrice() }));
    }
  }, [
    part.discount,
    part.discount_percentage,
    part.profit,
    part.profit_margin,
    part.gross_price,
    part.quantity,
    part.unitaryValue,
    part.images,
  ]);

  useEffect(() => {
    if (
      (part.quantity &&
        part.unitaryValue &&
        part.gross_price &&
        part.discount_percentage) ||
      part.discount
    ) {
      setPart((parts) => ({ ...parts, final_value: discountPrice() }));
    }
  }, [
    part.discount,
    part.discount_percentage,
    part.profit,
    part.profit_margin,
    part.gross_price,
    part.final_value,
    part.quantity,
    part.unitaryValue,
    part.images,
  ]);

  useEffect(() => {
    if (
      part.quantity &&
      part.unitaryValue &&
      part.gross_price &&
      part.profit_margin &&
      part.profit
    ) {
      setPart((parts) => ({ ...parts, discount_value: profitInputLabel() }));
    }
  }, [
    part.discount,
    part.discount_percentage,
    part.profit,
    part.profit_margin,
    part.gross_price,
    part.quantity,
    part.unitaryValue,
    part.images,
  ]);

  useEffect(() => {
    if (
      part.quantity &&
      part.unitaryValue &&
      part.gross_price &&
      part.discount_percentage &&
      part.discount
    ) {
      setPart((parts) => ({ ...parts, discount_value: discountInputLabel() }));
    }
  }, [
    part.discount,
    part.discount_percentage,
    part.profit,
    part.profit_margin,
    part.gross_price,
    part.quantity,
    part.unitaryValue,
    part.images,
  ]);

  useEffect(() => {
    if (
      part.quantity &&
      part.unitaryValue &&
      part.gross_price &&
      part.discount_percentage &&
      part.discount
    ) {
      setPart((parts) => ({ ...parts, images }));
    }
  }, [
    part.discount,
    part.discount_percentage,
    part.profit,
    part.profit_margin,
    part.gross_price,
    part.quantity,
    part.unitaryValue,
    part.images,
  ]);

  const onSave = async () => {
    try {
      const extraProps = {};
      if (part && part.images) {
        extraProps["images"] = JSON.stringify(part.images);
      }

      alertSuccess("Sucesso ao editar Peça!");
      closeModal();
      setParts((parts) =>
        parts.map((item) => {
          if (item.id === part.id) {
            return {
              ...part,
              value: discountPrice(),
              images: extraProps,
              // unitaryValue: part.final_value / part.quantity,
            };
          } else {
            return item;
          }
        })
      );
    } catch (error) {
      console.log({ error });
      alertError("Erro ao salvar o Peça, tente novamente mais tarde.");
    }
  };

  const closeModal = () => {
    setOpen(false);
  };

  function unitaryBrute() {
    const unitValue = parseFloat(
      (part?.unitaryValue || "R$").toString().replace(/[^\d.-]/g, "0")
    );
    if (part?.profit_margin) {
      const calculatedPercentagePrice =
        (unitValue * (part?.profit_margin || 0)) / 100;
      const calculatedBrutePrice = calculatedPercentagePrice + unitValue;
      return calculatedBrutePrice;
    } else {
      const profit = parseFloat(
        (part?.profit || "R$").replace(/[^\d.-]/g, "0")
      );
      const calculatedBruteProfit = unitValue + (profit || 0);
      return calculatedBruteProfit;
    }
  }

  function brutePrice() {
    const unitValueString = part?.unitaryValue;
    const unitValue =
      typeof unitValueString === "string"
        ? parseFloat(unitValueString.replace(",", "."))
        : parseFloat(
          (part?.unitaryValue || "R$").toString().replace(/[^\d.-]/g, "0")
        );
    if (part?.profit_margin !== undefined) {
      const calculatedPercentagePrice =
        (unitValue * (part?.profit_margin || 0)) / 100;

      const calculatedBrutePrice =
        (calculatedPercentagePrice + unitValue) * part?.quantity;
      return calculatedBrutePrice;
    } else {
      const profitString = part?.profit;
      const profit =
        typeof profitString === "string"
          ? parseFloat(profitString.replace(",", "."))
          : parseFloat(
            (part?.profit || "R$").toString().replace(/[^\d.-]/g, "0")
          );
      const calculatedBruteProfit =
        (unitValue + (profit || 0)) * part?.quantity;
      return calculatedBruteProfit;
    }
  }

  function discountPrice() {
    if (part?.discount_percentage !== undefined || part?.discount !== "R$") {
      if (part?.discount_percentage !== undefined) {
        const calculetedDiscountPrice =
          1 - (part?.discount_percentage || 0) / 100;
        const percentageDiscountPrice =
          calculetedDiscountPrice * part?.gross_price;
        return percentageDiscountPrice;
      } else {
        const discountString = part?.discount;
        const discount =
          typeof discountString === "string"
            ? parseFloat(discountString.replace(",", "."))
            : parseFloat(
              (part?.discount || "R$").toString().replace(/[^\d.-]/g, "0")
            );
        const calculatedBruteDiscount = part?.gross_price - (discount || 0);
        return calculatedBruteDiscount;
      }
    } else {
      return part?.gross_price;
    }
  }

  function profitInputLabel() {
    const unitValueString = part?.unitaryValue;
    const unitValue =
      typeof unitValueString === "string"
        ? parseFloat(unitValueString.replace(",", "."))
        : parseFloat(
          (part?.unitaryValue || "R$").toString().replace(/[^\d.-]/g, "0")
        );
    if (part?.profit_margin !== undefined) {
      const calculatedPercentagePrice =
        (unitValue * (part?.profit_margin || 0)) / 100;
      const profitValue = calculatedPercentagePrice * part?.quantity;
      return profitValue;
    } else {
      const profitString = part?.profit;
      const profit =
        typeof profitString === "string"
          ? parseFloat(profitString.replace(",", "."))
          : parseFloat(
            (part?.profit || "R$").toString().replace(/[^\d.-]/g, "0")
          );
      const calculatedBruteProfit = part?.quantity * profit;
      const profitBruteValue = calculatedBruteProfit;
      return profitBruteValue;
    }
  }

  function discountInputLabel() {
    if (part?.discount_percentage !== undefined || part?.discount !== "R$") {
      if (part?.discount_percentage !== undefined) {
        const calculetedDiscountPrice =
          1 - (part?.discount_percentage || 0) / 100;
        const percentageDiscountPrice =
          calculetedDiscountPrice * part?.gross_price;
        const percentDiscoutValue = percentageDiscountPrice - part?.gross_price;
        return percentDiscoutValue;
      } else {
        const discountString = part?.discount;
        const discount =
          typeof discountString === "string"
            ? parseFloat(discountString.replace(",", "."))
            : parseFloat(
              (part?.discount || "R$").toString().replace(/[^\d.-]/g, "0")
            );
        return discount;
      }
    } else {
      return part?.gross_price;
    }
  }

  const [isImageExpanded, setIsImageExpanded] = useState(false);

  const handleExpandImage = () => {
    setIsImageExpanded(true);
  };

  const handleCloseModal = () => {
    setIsImageExpanded(false);
  };

  return (
    <Modal
      onRequestClose={closeModal}
      className={"prospera-modal edit-piece-modal"}
      isOpen={isOpen}
    >
      <ModalBody className="modal-body">
        <div
          style={{ left: "50px" }}
          className="modal-container edit-piece-modal-container"
        >
          <div className="modal-header">
            <div className="header-title">
              <h1 className="modal-title">{part.name}</h1>
            </div>
            <div className="header-buttons">
              <DefaultButton
                className="modal-footer-button"
                label={""}
                icon={<AiOutlineCheck className="button-icon" />}
                onClick={onSave}
              />
              <DefaultButton
                className="modal-footer-button cancel"
                label={""}
                icon={<AiOutlineClose className="button-icon-cancel" />}
                onClick={closeModal}
              />
            </div>
          </div>
          <div style={{ paddingTop: "15px", paddingLeft: 5 }}>
            <LabelInput
              className="edit-piece-modal-input"
              label={"Código Peça"}
              value={part.piece_code}
              onChange={(value) => onChangePiece("piece_code", value)}
            />
            <LabelInput
              className="edit-piece-modal-input"
              label={"Marca"}
              value={part.piece_brand}
              onChange={(value) => onChangePiece("piece_brand", value)}
            />
            <LabelInput
              className="edit-piece-modal-input"
              label={"Fornecedor"}
              value={part.piece_supplier}
              onChange={(value) => onChangePiece("piece_supplier", value)}
            />
            <DropzoneInput
              table={"parts"}
              onChange={(value) => onChangePiece("images", value)}
              label={"Imagem"}
              value={part.images}
              single
              className="edit-piece-modal-input"
            />

            <div>
              {part.images ? (
                <DefaultButton
                  className="expand-image-button"
                  icon={<AiOutlineExpand className="button-icon-cancel" />}
                  onClick={handleExpandImage}
                />
              ) : null}

              {isImageExpanded && (
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.8)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1000,
                  }}
                  onClick={handleCloseModal}
                >
                  <img
                    src={`${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/${part.images}`}
                    alt="Expanded"
                    style={{ maxHeight: "80%", maxWidth: "80%" }}
                  />
                </div>
              )}

            </div>
          </div>
          <div style={{ paddingLeft: 5 }}>
            <LabelInput
              type="number"
              className="edit-piece-modal-input"
              label={"Quantidade"}
              value={part.quantity}
              onChange={(value) => onChangePiece("quantity", value)}
            />
            <MoneyInput
              type="number"
              className="edit-piece-modal-input"
              label={"Preço Custo"}
              value={part.unitaryValue}
              onChange={(value) => onChangePiece("unitaryValue", value)}
            />
            <MoneyInput
              type="number"
              className="edit-piece-modal-input"
              disabled={part?.profit_margin ? true : false}
              prefix={"R$"}
              label={"Margem R$"}
              value={part.profit}
              onChange={(value) => onChangePiece("profit", value)}
            />
            <PercentageInput
              placeholder={"%"}
              className="edit-piece-modal-input"
              disabled={part?.profit !== undefined}
              label={"Margem %"}
              value={part.profit_margin}
              onChange={(value) => onChangePiece("profit_margin", value)}
            />
            <MoneyInput
              className="edit-piece-modal-input"
              disabled={true}
              label={"Valor Lucro"}
              value={profitInputLabel()}
              onChange={(value) => onChangePiece("profit_value", value)}
            />
            <MoneyInput
              type="number"
              className="edit-piece-modal-input"
              label={"Preço Bruto"}
              disabled={true}
              value={brutePrice()}
              onChange={(value) => onChangePiece("gross_price", value)}
            />
            <MoneyInput
              type="number"
              className="edit-piece-modal-input"
              disabled={part.discount_percentage ? true : false}
              prefix={"R$"}
              label={"Desconto R$"}
              value={part.discount}
              onChange={(value) => onChangePiece("discount", value)}
            />
            <PercentageInput
              placeholder={"%"}
              className="edit-piece-modal-input"
              disabled={part?.discount !== undefined}
              label={"Desconto %"}
              value={part.discount_percentage}
              onChange={(value) => onChangePiece("discount_percentage", value)}
            />
            <MoneyInput
              className="edit-piece-modal-input"
              disabled={true}
              label={"Valor Desconto"}
              value={discountInputLabel()}
              onChange={(value) => onChangePiece("discount_value", value)}
            />
            <MoneyInput
              type="number"
              className="edit-piece-modal-input"
              label={"Preço Final"}
              disabled={true}
              value={discountPrice()}
              onChange={(value) => onChangePiece("final_value", value)}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PieceEdit;
