import React from 'react';
import { BUDGET_TABS } from '../utils/enum';

export const TotalBudget = (props) => {
  const { totalParts, totalSum, totalBodyshopService, totalAeService, budget } =
    props;

  if (
    budget === BUDGET_TABS.DETAILS ||
    budget === BUDGET_TABS.NOTES ||
    budget === BUDGET_TABS.ENTRY_CHECKLIST
  ) {
    return;
  }
  return (
    <div className="values-box">
      <h4 className="values-title">
        Total funilaria:{' '}
        <span className="values-span">
          {(totalBodyshopService ?? 0).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
        </span>
      </h4>
      <h4 className="values-title">
        Total estética:{' '}
        <span className="values-span">
          {(totalAeService ?? 0).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
        </span>
      </h4>
      <h4 className="values-title">
        Total peças:{' '}
        <span className="values-span">
          {(totalParts ?? 0).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
        </span>
      </h4>
      <h4 className="values-title">
        Total orçamento:{' '}
        <span className="values-span">
          {(totalSum ?? 0).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
        </span>
      </h4>
    </div>
  );
};
