import { supabase } from '../../supabaseClient';

export const saveBudget = async (id = undefined, item) => {
  if (id) {
    return await supabase
      .from('Budget')
      .update({ ...item })
      .match({ id })
      .select(
        `
        *,
        Vehicle(responsible, *)
        `
      );
  } else {
    return await supabase.from('Budget').insert([{ ...item }]);
  }
};

export const savePaymentTypes = async (item) => {
  return await supabase.from('PaymentTypes').insert([{ ...item }]);
};

export const saveConsultant = async (item) => {
  return await supabase.from('Consultants').insert([{ ...item }]);
};

export const saveTypeOS = async (item) => {
  return await supabase.from('TypeOS').insert([{ ...item }]);
};

export const deletedBudget = async (id = undefined, user_id) => {
  if (id) {
    return await supabase
      .from('Budget')
      .update({ deleted_at: new Date(), deleted_by: user_id })
      .eq('id', id);
  }
};
