import React, { useState } from 'react';
import { LabelInput } from '../../../components/Input/Input';
import { DefaultButton } from '../../../components/Button/Button';
import './styles.scss';
import { Button } from 'reactstrap';
import { BsFillTrashFill } from 'react-icons/bs';

export const ModalCompanyChecklistTab = (props) => {
  const { values, onChange } = props;
  const [checklistName, setChecklistName] = useState('');

  const checklist = values.checklist || [];

  function handleAddChecklist() {
    setChecklistName('');
    onChange('checklist', [...checklist, { name: checklistName }]);
  }

  function handleDeleteChecklist(item) {
    onChange(
      'checklist',
      checklist.filter((checklistItem) => checklistItem.name !== item.name)
    );
  }

  console.log(values);

  return (
    <div className="modal-company-checklist-tab-content">
      <div className="modal-company-checklist-tab-create-container">
        <LabelInput
          className="service-type-description"
          value={checklistName}
          onChange={(value) => setChecklistName(value)}
          placeholder={'Insira a checklist'}
          label={'Nome da Checklist'}
        />

        <div>
          <DefaultButton
            disabled={!checklistName}
            className="percentage-button"
            onClick={handleAddChecklist}
            label={'+'}
          />
        </div>
      </div>

      {checklist?.map((item) => (
        <div style={{ display: 'flex', width: '100%' }}>
          <div style={{ width: '100%' }}>
            <LabelInput
              className="subservice-item"
              disabled={true}
              value={item?.name}
            />
          </div>

          <div
            className="delete-service-placement"
            style={{ paddingTop: 10, paddingRight: 5, paddingLeft: 10 }}
          >
            <Button
              style={{
                borderColor: '#ffaaa4',
                backgroundColor: 'transparent',
                height: 40,
                width: 50,
                borderRadius: 5,
              }}
              onClick={() => handleDeleteChecklist(item)}
            >
              <BsFillTrashFill className="button-icon-delete" />
            </Button>
          </div>
        </div>
      ))}

      {checklist.length === 0 && (
        <span style={{ color: 'gray' }}>Nenhuma checklist cadastrada</span>
      )}
    </div>
  );
};
