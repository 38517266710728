import {
  CNPJInput,
  DropzoneInput,
  LabelInput,
  SwitchInput,
  TableInput,
  CellphoneInput,
  CEPInput,
  CnaeInput,
  SelectInput,
} from '../../components/Input/Input';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { DefaultButton } from '../../components/Button/Button';
import ButtonSwitch from '../../components/Switch';
import {
  BsBuilding,
  BsEnvelope,
  BsTrashFill,
  BsBuildingAdd,
  BsKeyFill,
  BsPeople,
  BsCardText,
  BsFillTrashFill,
  BsCheckCircle,
} from 'react-icons/bs';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { isMobile } from 'react-device-detect';
import { inPlaceSort } from 'fast-sort';
import { useEffect, useState } from 'react';
import { COUNTRIES } from '../../core/constants/countries';
import { CustomSelectCountries } from '../ModalClients/components/CustomSelectCountries';
import { isArray } from 'lodash';
import { ModalCompanyChecklistTab } from './tabs/Checklist';

const ModalCompany = ({
  loggedUser,
  changeCEP,
  users,
  values,
  closeModal,
  isNew,
  addUser,
  onChange,
  onSave,
  onRemoveUser,
  onEditPassword,
  onChangeType,
  onSendEmail,
  compareValues,
  onRemove,
  companyList,
}) => {
  const currentCountrie = COUNTRIES.find(
    (country) => country?.iso === values.countrie
  );
  const [selectedCountry, setSelectedCountry] = useState(currentCountrie);

  useEffect(() => {
    onChange('countrie', values?.countrie || 'BR');
    setSelectedCountry(currentCountrie);
  }, [values.countrie]);

  return (
    <div className="modal-container">
      <div className="modal-header">
        <div className="header-title">
          <h4 className="modal-title">
            {isNew ? 'Adicionar Empresa' : 'Editar Empresa'}
          </h4>
        </div>
        <div className="header-buttons">
          {compareValues ? (
            <DefaultButton
              className="modal-footer-button"
              label={isMobile ? '' : 'Salvar'}
              icon={<AiOutlineCheck className="button-icon" />}
              onClick={onSave}
            />
          ) : (
            <div />
          )}
          <DefaultButton
            className="modal-footer-button cancel"
            label={isMobile ? '' : 'Cancelar'}
            icon={<AiOutlineClose className="button-icon-cancel" />}
            onClick={closeModal}
          />
        </div>
      </div>

      <Tabs classname="main-tab">
        <TabList style={{ display: 'flex', flexFlow: 'row' }}>
          <Tab style={{ width: '100%' }}>
            <text> Dados Básicos </text>
            <BsBuilding />
          </Tab>
          <Tab style={{ width: '100%' }}>
            <text> Dados Completos </text>
            <BsBuildingAdd />
          </Tab>
          <Tab style={{ width: '100%' }}>
            <text> Usuários </text>
            <BsPeople />
          </Tab>
          <Tab style={{ width: '100%' }}>
            <text> Observações </text>
            <BsCardText />
          </Tab>
          {/* <Tab style={{ width: '100%' }}>
            <text> Checklists </text>
            <BsCheckCircle />
          </Tab> */}
        </TabList>

        <TabPanel>
          <div className="desc-input company">
            <div className="main-upper-div">
              <div className="div-dropzone">
                <DropzoneInput
                  key={values?.logo}
                  onChange={(value) => onChange('logo', value)}
                  label={'Imagem'}
                  value={values?.logo}
                  single
                />
              </div>
              <div className="div-name-cnpj">
                <LabelInput
                  value={values.name}
                  onChange={(value) => onChange('name', value)}
                  placeholder={'Insira a Razão Social da empresa'}
                  label={'Razão Social*'}
                />
                <LabelInput
                  value={values.tradingName}
                  onChange={(value) => onChange('tradingName', value)}
                  placeholder={'Insira o nome fantasia da empresa'}
                  label={'Nome Fantasia'}
                />
              </div>
            </div>
            <div className="desc-line last">
              <CustomSelectCountries
                onChange={onChange}
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
              />
              <CellphoneInput
                value={values.cellphone}
                onChange={(value) => onChange('cellphone', value)}
                placeholder={'Insira o celular'}
                label={'WhatsApp*'}
                mask={
                  isArray(selectedCountry?.mask)
                    ? selectedCountry?.mask[0]
                    : selectedCountry?.mask
                }
              />
              <CellphoneInput
                value={values.telephone}
                onChange={(value) => onChange('telephone', value)}
                placeholder={'Insira o telefone'}
                label={'Telefone'}
                mask="(99) 9999-9999"
              />
            </div>
            <LabelInput
              value={values.email}
              onChange={(value) => onChange('email', value)}
              placeholder={'Insira o e-mail'}
              label={'E-mail'}
            />
          </div>
          {isNew ? (
            <div className="size-dropdown">
              <text
                style={{
                  color: 'white',
                  fontStyle: 'normal',
                  fontFamily: 'poppins',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
              >
                {' '}
                Copiar da Empresa{' '}
              </text>
              <div
                style={{ padding: '0px 5px 5px 5px' }}
                className="void-class"
              >
                <SelectInput
                  placeholder="Selecione"
                  options={inPlaceSort(companyList).asc([
                    (item) =>
                      item.label.replace(/[^a-zA-Z]/g, '').toLowerCase(),
                    (item) => {
                      const numberMatch = item.label.match(/\d+/);
                      return numberMatch ? parseInt(numberMatch[0], 10) : 0;
                    },
                  ])}
                  onChange={(value) => onChange('selectedCompany', value)}
                />
              </div>
              {values?.selectedCompany ? (
                <div
                  style={{
                    display: 'flex',
                    flexFlow: 'column',
                    backgroundColor: '#67676a',
                    margin: '5px',
                    padding: '5px',
                    borderRadius: '4px',
                  }}
                >
                  <label className="company-checkbox">
                    <ButtonSwitch
                      value={values.copyUsers}
                      defaultValue={values.copyUsers}
                      hasLabel={false}
                      onChange={(value) => onChange('copyUsers', value)}
                    />
                    Copiar Usuários
                  </label>
                  <label className="company-checkbox">
                    <ButtonSwitch
                      value={values.copyConfig}
                      defaultValue={values.copyConfig}
                      hasLabel={false}
                      onChange={(value) => onChange('copyConfig', value)}
                    />
                    Copiar Configurações
                  </label>
                </div>
              ) : null}
            </div>
          ) : null}
        </TabPanel>
        <TabPanel>
          <div className="desc-input company">
            <div className="main-upper-div">
              <div className="div-name-cnpj">
                <CNPJInput
                  value={values.cnpj}
                  onChange={(value) => onChange('cnpj', value)}
                  placeholder={'Insira o CPF ou CNPJ'}
                  label={'CPF* ou CNPJ*'}
                />
                <LabelInput
                  value={values.stateRegistration}
                  onChange={(value) => onChange('stateRegistration', value)}
                  placeholder={'Insira a inscrição estadual'}
                  label={'Inscrição Estadual'}
                />
                <LabelInput
                  value={values.municipalRegistration}
                  onChange={(value) => onChange('municipalRegistration', value)}
                  placeholder={'Insira a inscrição municipal'}
                  label={'Inscrição Municipal'}
                />
                <LabelInput
                  value={values.taxRegime}
                  onChange={(value) => onChange('taxRegime', value)}
                  placeholder={'Insira o regime tributário'}
                  label={'Regime Tributário'}
                />
                <CnaeInput
                  value={values.cnae}
                  onChange={(value) => onChange('cnae', value)}
                  placeholder={'Insira o CNAE'}
                  label={'CNAE'}
                />
                <CEPInput
                  value={values.cep}
                  onChange={(value) => changeCEP(value)}
                  placeholder={'Insira o CEP'}
                  label={'CEP*'}
                />
                <div className="desc-line">
                  <LabelInput
                    value={values.street}
                    onChange={(value) => onChange('street', value)}
                    placeholder={'Rua'}
                    label={'Rua*'}
                  />
                  <LabelInput
                    type="number"
                    value={values.number}
                    onChange={(value) => onChange('number', value)}
                    placeholder={'Número'}
                    label={'Número'}
                  />
                  <LabelInput
                    value={values.complement}
                    onChange={(value) => onChange('complement', value)}
                    placeholder={'Insira o Complemento'}
                    label={'Complemento'}
                  />
                </div>
                <div className="desc-line last">
                  <LabelInput
                    value={values.neighborhood}
                    onChange={(value) => onChange('neighborhood', value)}
                    placeholder={'Bairro'}
                    label={'Bairro*'}
                  />
                  <LabelInput
                    value={values.city}
                    onChange={(value) => onChange('city', value)}
                    placeholder={'Cidade'}
                    label={'Cidade*'}
                  />
                  <LabelInput
                    value={values.state}
                    onChange={(value) => onChange('state', value)}
                    placeholder={'UF'}
                    label={'UF*'}
                  />
                </div>
                <div style={{ marginTop: 10 }}>
                  <SwitchInput
                    value={values.active}
                    onChange={(value) => onChange('active', value)}
                    label={'Empresa Ativa'}
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="desc-input">
            <TableInput
              onChange={(email) => addUser(email)}
              label={'Usuários'}
              placeholder={'Insira o email para convidar novos usuários'}
              columns={columns(
                isNew,
                onRemoveUser,
                onChangeType,
                onSendEmail,
                onEditPassword
              )}
              rows={users}
              className="gridtable-on-company default-table better-add-button"
            />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="desc-input company">
            <div className="main-upper-div">
              <div className="div-name-cnpj">
                <LabelInput
                  className="cellphone-company-input"
                  type={'textarea'}
                  value={values.budgetNote}
                  onChange={(value) => onChange('budgetNote', value)}
                  placeholder={'...'}
                  label={'Nota orçamento'}
                />
                <LabelInput
                  className="cellphone-company-input"
                  type={'textarea'}
                  value={values.serviceOrderNote}
                  onChange={(value) => onChange('serviceOrderNote', value)}
                  placeholder={'...'}
                  label={'Nota ordem de serviço'}
                />
                <div className="desc-line"></div>
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel>
          <ModalCompanyChecklistTab values={values} onChange={onChange} />
        </TabPanel>
      </Tabs>
      {values &&
      values.id &&
      loggedUser &&
      loggedUser.id &&
      (loggedUser.id === 1 || loggedUser.id === 2) ? (
        <DefaultButton
          className="modal-footer-button delete"
          label={'Excluir'}
          icon={<BsFillTrashFill className="button-icon-cancel" />}
          onClick={onRemove}
        />
      ) : (
        <div />
      )}
      <div className="modal-footer-table"></div>

      {/* {showEditPassword && (
        <div className="budget-modal-main-div">
          <LabelInput
            classLabel={'loginLabel'}
            className={'login-input password'}
            label={"Insira uma senha para o usuário"}
            value={userPassword}
            placeholder={"Insira a senha"}
            type={'text'}
            onChange={value => setUserPassword(value)}
          />

          <DefaultButton
            className="save-pass-button"
            label={isMobile ? "" : ""}
            icon={<AiOutlineCheck className="button-icon" />}
            onClick={() => handleEditPassword(currentUser)}
          />
        </div>
      )} */}
    </div>
  );
};

export default ModalCompany;

const validateCnpj = (value) => {
  const regex = /^([0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2})$/;
  return regex.test(value);
};

const columns = (
  isNew,
  onRemoveUser,
  onChangeType,
  onSendEmail,
  onEditPassword
) => [
  {
    id: 1,
    field: 'name',
    label: 'Nome',
    width: '1fr',
    className: 'field-name',
    resizable: false,
  },
  {
    id: 2,
    field: 'type',
    label: 'Tipo',
    width: '11px',
    resizable: false,
    cellRenderer: ({ data }) => {
      const { type } = data;
      let text, color;

      switch (type) {
        case 0:
          text = isMobile ? 'C' : 'Convidado';
          color = '#2D2D2D';
          break;
        case 1:
          text = isMobile ? 'A' : 'Administrador';
          color = '#94CD3C';
          break;
        case 2:
          text = isMobile ? 'G' : 'Gestor';
          color = '#262338';
          break;
        case 3:
          text = isMobile ? 'M' : 'Membro';
          color = '#4E4B66';
          break;
        default:
          text = null;
          color = '#FFFFFF';
      }

      return (
        text && (
          <div
            style={{
              // height: '25px',
              padding: '2px 6px',
              borderRadius: '8px',
              backgroundColor: color,
              cursor: type !== 0 ? 'pointer' : 'default',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              color: '#F7F7FC',
            }}
            onClick={() => onChangeType(data)}
          >
            <span
              style={{
                fontWeight: 300,
                fontSize: '14px',
                letterSpacing: '0.25px',
                color: '#F7F7FC',
              }}
            >
              {text}
            </span>
          </div>
        )
      );
    },
  },
  {
    id: 3,
    field: 'actions',
    label: 'Ações',
    resizable: false,
    width: '75px',
    cellRenderer: ({ data }) =>
      isNew ? (
        <></>
      ) : (
        <ActionCell
          data={data}
          onRemoveUser={onRemoveUser}
          onEditPassword={onEditPassword}
          onSendEmail={onSendEmail}
        />
      ),
  },
];

const ActionCell = ({ data, onRemoveUser, onSendEmail, onEditPassword }) => {
  return (
    <div className="field-actions">
      {data && !data.isRegistered ? (
        <BsEnvelope
          className="icon-action send"
          onClick={() => onSendEmail(data)}
        />
      ) : (
        <div />
      )}
      <BsTrashFill
        className="icon-action trash"
        onClick={() => onRemoveUser(data)}
      />
      <BsKeyFill
        className="icon-action edit"
        onClick={() => onEditPassword(data)}
        // onClick={() => setShowEditPassword(true)}
      />
    </div>
  );
};
