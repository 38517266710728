import React from 'react';
import { BsChevronDown, BsThreeDotsVertical } from 'react-icons/bs';
import {
  CardHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import perfilLogo from '../../assets/images/perfil.png';
import perfilLogoDark from '../../assets/images/logoDark.png';
import { IoAddCircleOutline, IoExitOutline } from 'react-icons/io5';
import ReactImageFallback from 'react-image-fallback';
import { isMobile } from 'react-device-detect';
import HomeButton from '../../components/homeButton/homeButton';

const HeaderScreen = ({
  route,
  onLogout,
  data,
  selected,
  changeCompany,
  history,
  courseName,
}) => {
  const { location } = history;

  if (courseName) {
    return (
      <CardHeader className="card-header">
        <div style={{ flex: 1 }}>
          <h6 className="title">{courseName}</h6>
        </div>
        <div className="flex-header">
          <HomeButton routeBackName="/manual" />
        </div>
      </CardHeader>
    );
  }

  function sortByName(array) {
    return array?.slice?.()?.sort((a, b) => a?.name?.localeCompare(b?.name));
  }

  return location.pathname === '/home' ? (
    <CardHeader className="card-header">
      <div style={{ flex: 1 }}>
        {route && route.title ? (
          <h6 className="title">{route.title}</h6>
        ) : (
          <div />
        )}
      </div>

      <div className="flex-header">
        {route && route.action ? route.action(history) : <div />}
        <UncontrolledDropdown className="btn-rotate">
          <DropdownToggle
            aria-haspopup={true}
            caret={false}
            color="#000"
            data-toggle="dropdown"
            // id="navbarDropdownMenuLink"
            nav
          >
            <div className="perfil main">
              <ReactImageFallback
                src={`${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/${selected.logo}`}
                fallbackImage={
                  require('../../assets/images/company_fallback_img.jpg')
                    .default
                }
                initialImage={
                  require('../../assets/images/dark_loader.gif').default
                }
                alt=""
                className="perfil-logo"
              />
              {isMobile ? (
                <></>
              ) : (
                <h6 className="perfil-text">{selected.name}</h6>
              )}
              <BsChevronDown className="perfil-icon" />
            </div>
          </DropdownToggle>
          <DropdownMenu
            style={{ maxHeight: 450, overflowY: 'auto' }}
            className="dropdown-menu-header"
            aria-labelledby="navbarDropdownMenuLink"
            right
          >
            {sortByName(data)
              .filter((item) => item.id !== selected.id)
              .map((item) => (
                <DropdownItem
                  key={item?.id}
                  onClick={() => changeCompany(item)}
                  className={'drop-item'}
                >
                  <div className="perfil">
                    <ReactImageFallback
                      src={`${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/${item.logo}`}
                      fallbackImage={
                        require('../../assets/images/company_fallback_img.jpg')
                          .default
                      }
                      initialImage={
                        require('../../assets/images/dark_loader.gif').default
                      }
                      alt=""
                      className="perfil-logo"
                    />
                    <h6 className="perfil-text">{item.name}</h6>
                  </div>
                </DropdownItem>
              ))}
            {/* <DropdownItem
                    onClick={() => { }}
                    className={'drop-item'}
                >
                    <div className='perfil' >
                        <IoAddCircleOutline size={30} />
                        <h6 className='perfil-text'>Adicionar Empresa</h6>
                    </div>
                </DropdownItem> */}

            <DropdownItem onClick={onLogout} className={'drop-logout'}>
              <div className="perfil-logout">
                <IoExitOutline size={30} />
                <h6 className="perfil-text">Sair</h6>
              </div>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </CardHeader>
  ) : (
    <CardHeader className="card-header">
      <div style={{ flex: 1 }}>
        {route && route.title ? (
          <h6 className="title">{route.title}</h6>
        ) : (
          <div />
        )}
      </div>
      <div className="flex-header">
        {route && route.action ? route.action(history) : <div />}
      </div>
    </CardHeader>
  );
};

export default HeaderScreen;
